<template>
  <div class="container">
    <header>
      <h3>
        <v-icon small>mdi-account</v-icon>
        <strong>username: {{ currentUser.username }}</strong>
        <span class="font-weight-light caption">*{{ currentUser.id }}</span>
      </h3>
      <p>
        <v-icon small>mdi-email</v-icon> <strong>email: </strong>
        {{ currentUser.email }}
      </p>
    </header>
    <div class="caption">
      <strong>Token:</strong>
      {{ currentUser.accessToken.substring(0, 20) }} ...
      {{ currentUser.accessToken.substr(currentUser.accessToken.length - 20) }}
    </div>

    <strong>Authorities:</strong>
    <ul class="subtitle-2">
      <li v-for="(role, index) in currentUser.roles" :key="index">
        {{ role }}
      </li>
    </ul>

    <div class="mt-4">
      <strong>Dinas/OPD: </strong>
      {{ lookupFDivision(currentUser.fdivisionBean) }}
      <span class="font-weight-light caption"
        >*{{ currentUser.fdivisionBean }}</span
      >
    </div>
    <div>
      <strong>Level Organisasi: </strong>
      {{ currentUser.organizationLevel }}
    </div>
  </div>
</template>

<script>
import FDivisionService from "@/services/apiservices/f-division-service";

export default {
  name: "Profile",
  data() {
    return {
      selectFDivision: { id: 0, kode1: "", description: "-" },
      itemsFDivision: [{ id: 0, kode1: "", description: "-" }],
      itemsOrganizationLevel: ["CORP", "DIV"],
      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 3 || "Harus lebih besar dari 3 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
  },
  methods: {
    fetchParent() {
      FDivisionService.getAllFDivision().then(
        (response) => {
          // console.log(response.data)
          this.itemsFDivision = response.data;
        },
        (error) => {
          console.log(error.response);
        }
      );
      // FSalesmanService.getAllFSalesman().then(
      //     response => {
      //       // console.log(response.data)
      //       this.itemsFSalesman = response.data
      //     },
      //     error => {
      //       console.log(error.response)
      //     }
      // )
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id == fdivisionBean);
      if (str.length > 0) {
        return str[0].description;
      } else {
        return "-";
      }
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login");
      return;
    }

    // this.fetchParent()
  },
};
</script>